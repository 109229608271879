<template>
    <div class="add-new-branch">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.branchCreate')"
            :title="$t('messages.branchCreate')"
            @submitPressed="submitPressed"
        />
        <resource-form :resource-object="branch" :resource="$Branches" base-url="/settings/branches"
                       create-message="messages.branchCreated" update-message="messages.branchUpdated" :action="action"
                       @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            action: null,
            branch: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
